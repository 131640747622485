import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SecondPage = () => (
 <Layout styleClass="careers">
  <SEO title="Careers at MSM" />

  <div id="grnhse_app">
   <iframe id="grnhse_iframe" width="100%" frameBorder="0" scrolling="no" allow="geolocation" title="Greenhouse Job Board" src="https://boards.greenhouse.io/embed/job_board?for=msminc&amp;b=http%3A%2F%2Fwww.msmnet.com%2Fcareers%2Fopen-positions%2F" height="752"></iframe>
  </div>

 </Layout>
)

export default SecondPage